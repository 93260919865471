/* Our Story */
.about-sec {
  padding: 40px;
  background-color: #f9f9f9; /* Light background color */
}

.about-sec-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

.about-image img {
  width: 100%;
  max-width: 300px; /* Adjust size as needed */
  height: auto;
  margin-left: 10rem;
  border-radius: 8px; /* Optional: Rounded corners for image */
}

.about-text {
  flex: 1;
  margin-left: 5rem;
}

.about-text h2 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #ff5e14;
}

.about-text p {
  text-align: left;
  margin-top: 20px;
  color: #333;
  font-size: 1rem;
}

.features-list {
  list-style: none;
  padding: 0;
}

.features-list li {
  font-size: 12px;
  margin-bottom: 10px;
  color: #333; /* Dark color for text */
  display: flex;
  align-items: center;
}

.features-list i {
  font-size: 12px;
  color: #ff5e14;
  margin-right: 10px;
}

/* Responsive Styles for Small Screens */
@media (max-width: 768px) {
  .about-image {
    display: none; /* Hide image on small screens */
  }

  .about-image img {
    max-width: 100%; /* Ensure the image scales down */
  }

  .about-text {
    margin-left: 0; /* Remove left margin */
    text-align: center; /* Center text for smaller screens */
  }

  .about-text h2 {
    font-size: 1.25rem; /* Reduce font size for headings */
  }

  .about-text p {
    font-size: 12px; /* Reduce font size for paragraphs */
  }

  .features-list li {
    font-size: 11px; /* Reduce font size for list items */
  }

  .features-list i {
    font-size: 11px; /* Reduce font size for icons */
  }
}

@media (max-width: 480px) {
  .about-sec {
    padding: 20px; /* Reduce padding */
  }

  .about-text h2 {
    font-size: 1rem; /* Further reduce font size for headings */
  }

  .about-text p {
    font-size: 12px; /* Further reduce font size for paragraphs */
  }

  .features-list li {
    font-size: 10px; /* Further reduce font size for list items */
  }

  .features-list i {
    font-size: 10px; /* Further reduce font size for icons */
  }
}

/* What We Offer Section Styles */
.what-we-offer {
  padding: 20px;
  background-color: #fff; /* White background color for contrast */
  text-align: center;
}

.what-we-offer h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #ff5e14; /* Primary color for heading */
}

.offer-cards {
  display: flex;
  justify-content: space-around;
  gap: 10px;
  flex-wrap: wrap; /* Allow wrapping for smaller screens */
}

.offer-card {
  background-color: #f4f4f4; /* Light background for cards */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Shadow for depth */
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 300px; /* Default card width */
}

.offer-card img {
  width: 100%; /* Make image responsive */
  height: auto; /* Maintain aspect ratio */
  border-radius: 5%;
  margin-top: 10px;
}

.offer-text {
  padding: 20px;
}

.offer-text h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #333; /* Dark color for text */
}

.offer-text p {
  font-size: 1rem;
  margin-bottom: 20px;
  color: #666; /* Lighter color for text */
}

.offer-btn {
  background-color: #ff5e14; /* Primary color for button */
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.offer-btn:hover {
  background-color: #e14e00; /* Darker shade for hover effect */
}

.offer-card:hover {
  transform: scale(1.05); /* Slight zoom effect */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Enhanced shadow */
}

/* Responsive Styles for Small Screens */
@media (max-width: 768px) {
  .offer-cards {
    justify-content: center; /* Center cards on smaller screens */
  }

  .offer-card {
    width: 250px; /* Adjust card width for smaller screens */
  }

  .offer-text h3 {
    font-size: 1.25rem; /* Reduce font size for headings */
  }

  .offer-text p {
    font-size: 0.875rem; /* Reduce font size for text */
  }

  .offer-btn {
    font-size: 0.875rem; /* Reduce button font size */
  }
}

@media (max-width: 480px) {
  .offer-card {
    width: 100%; /* Full width cards for very small screens */
    margin-bottom: 20px; /* Add spacing between cards */
  }

  .offer-text h3 {
    font-size: 1rem; /* Further reduce font size for headings */
  }

  .offer-text p {
    font-size: 1rem; /* Further reduce font size for text */
  }

  .offer-btn {
    font-size: 0.75rem; /* Further reduce button font size */
  }
}
/* Our Vision Section Styles */
.our-vision {
  padding: 40px;
  background-color: #eef2f3; /* Light grey background for contrast */
  text-align: center;
}

.our-vision h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #ff5e14; /* Primary color for heading */
}

.our-vision p {
  font-size: 1rem;
  color: #333; /* Dark color for text */
  max-width: 600px;
  margin: 0 auto; /* Center the paragraph */
  
}
@media (max-width: 768px) {
  .our-vision p{
    font-size: 12px;
    text-align: left;
  }
}

/*About Section*/
.About-section {
  position: relative;
  width: 100%;
  height: 15rem;
  overflow: hidden;
}

.About-background {
  position: relative;
  background-image: url("Offer1.jpg");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.About-background::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.About-content {
  position: relative;
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  z-index: 2;
}

.About-content h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.About-content p {
  font-size: 1rem;
  line-height: 1.6;
}

.contact-us-button1 {
  background-color: #ff7e20;
  border: none;
  color: rgb(0, 0, 0);
  padding: 10px 20px;
  font-size: 12px;
  cursor: pointer;
  font-weight: bold;
  border-radius: 5px;
  text-transform: uppercase;
}

.contact-us-button1:hover {
  transition: 0.2s;
  background-color: #000000;
  color: #ff7e20;
  transform: scale(1.05);
}

/* Responsive Styles for Small Screens */
@media (max-width: 768px) {
  .About-section {
    height: auto; /* Allow height to adjust based on content */
  }

  .About-background {
    background-attachment: scroll; /* Disable parallax effect on mobile */
  }

  .About-content {
    padding: 15px; /* Reduce padding for smaller screens */
  }

  .About-content h2 {
    font-size: 2rem; /* Reduce heading size */
  }

  .About-content p {
    font-size: 1rem; /* Reduce paragraph text size */
  }
}

@media (max-width: 480px) {
  .About-section {
    height: auto; /* Ensure height adapts to content */
  }

  .About-content {
    padding: 10px; /* Further reduce padding */
  }

  .About-content h2 {
    font-size: 1.75rem; /* Further reduce heading size */
  }

  .About-content p {
    font-size: 0.9rem; /* Further reduce paragraph text size */
  }
}

/*About$$$$$$$$$$$$$$$$$*/

.Contact-header {
    width: 100%;
    height: auto;
  }
  
  .Contact-static-background {
    background-image: url("contact.png");
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 15rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative; /* Required for overlay positioning */
    overflow: hidden; /* Ensures child elements don’t overflow */
  }
  
  .Contact-static-background::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
    filter: blur(5px); /* Blur effect */
    z-index: 1; /* Places the overlay below the content */
  }
  
  .Contact-content {
    text-align: center;
    color: #ff5e14;
    position: relative; /* Ensures content is above the overlay */
    z-index: 2; /* Above the overlay */
  }
  
  .Contact-content h1 {
    font-size: 2rem; /* Adjusted size for better visibility */
    margin: 0;
  }
  
  .Contact-content p {
    font-size: 1rem;
    margin: 20px auto 0;
    color: #ffffff;
    max-width: 540px;
  }
  
.review-section {
  padding: 30px;
  text-align: center;
  margin: 0 auto;
  max-width: 80%;
}

.review-title {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 30px;
  font-weight: bold;
}

.slide {
  position: relative;
  transition: transform 0.2s ease, filter 0.2s ease;
}

.slick-center .slide {
  transform: scale(1.2); /* Make the center slide larger */
  z-index: 2; 
}

.slick-slide:not(.slick-center) .slide {
  transform: scale(0.8); /* Make the side slides smaller */
  z-index: 1;
}

.review-card {
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  height: 150px; /* Increased height */
  width: 250px;  /* Reduced width */
  margin: 0 0;
  overflow: hidden;
}

.review-card h3 {
  font-size: 1rem;
  color: #333;
  margin-bottom: 10px;
}

.stars {
  margin: 10px 0;
}

.review-card p {
  font-size: 12px;
  color: #666;
}

.arrow {
  font-size: 3rem;
  color: #333;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;
  cursor: pointer;
  border-radius: 50%;
  padding: 30px;
}

.arrow.prev {
  left: -20px; 
}

.arrow.next {
  right: -20px;
}

.arrow:hover {
  color: #ff5e14; 
}

/* Contact Us Section */
.contact-us {
  padding: 50px 20px;
  background-color: #f9f9f9;
  align-items: flex-start; /* Align items to the start */
}

.contact-container {
  display: flex;
  flex-wrap: wrap; /* Enable wrapping for smaller screens */
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1200px;
  margin: 0 auto;
}

.contact-info {
  width: 45%; /* Adjusted width for better alignment */
  padding: 20px;
  background-color: #ffffff;
  margin: 20px 10px; /* Added margin for spacing */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex-grow: 1; /* Allow growing to fill available space */
}

.contact-info h2 {
  margin-bottom: 20px;
  color: #ff5e14;
  font-size: 1.8rem;
}

.contact-info-item {
  font-size: 14px;
  margin-bottom: 15px;
  align-items: left;
  margin-right: 10px;
  display: flex;
}
.contact-info-item b {
  margin-right: 5px; /* Adjust the value as needed for spacing */
}

.contact-info-item i {
  font-size: 20px;
  color: #ff5e14;
  margin-right: 10px;
}

.contact-form-wrapper {
  width: 45%; /* Adjusted width for better alignment */
  padding: 20px;
  background-color: #ffffff;
  margin: 20px 10px; /* Added margin for spacing */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex-grow: 1; /* Allow growing to fill available space */
}

.contact-form {
  position: relative;
  z-index: 2;
  padding: 30px; /* Adjusted padding */
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  height: auto; /* Auto height for better responsiveness */
}

.contact-form h2 {
  margin-bottom: 20px;
  color: #ff5e14;
  font-size: 1.8rem;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  font-size: 16px;
  outline: none;
}

.form-group input:focus,
.form-group textarea:focus {
  border-color: #ff5e14;
}

.form-group i {
  color: #ff5e14;
}

.send-button {
  padding: 12px 20px;
  background-color: #ff5e14;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.send-button:hover {
  background-color: #e64e00;
  transition: background-color 0.3s ease;
}

/* Responsive Styles for Small Screens */
@media (max-width: 768px) {
  .contact-container {
    flex-direction: column;
    align-items: center; /* Center items for small screens */
  }

  .contact-info,
  .contact-form-wrapper {
    width: 90%; /* Full width with some padding on small screens */
    margin: 10px 0;
  }

  .contact-info,
  .contact-form {
    padding: 20px;
    height: auto; /* Adjust height for smaller screens */
  }

  .contact-info h2,
  .contact-form h2 {
    font-size: 1.5rem;
  }

  .contact-info-item {
    font-size: 14px; /* Adjust font size for smaller screens */
  }

  .contact-form input[type="tel"] {
    padding: 8px;
    font-size: 14px; /* Adjust font size for smaller screens */
  }
}

/* Contact Form Wrapper when submitted */
.contact-form-wrapper.submitted .contact-form {
  display: none;
}

.thank-you-message {
  padding: 50px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px 10px;
  text-align: center;
}

.thank-you-message h2 {
  color: #ff5e14;
  font-size: 1.8rem;
  margin-bottom: 20px;
}

.thank-you-message p {
  font-size: 1rem;
}
.map-container {
  margin-top: 40px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

::selection {
  background-color: #ff7e20;
  color: white;
}

/* Info Section Styles */
.info-section {
  background-color: white;
  padding: 20px;
  height: 25rem;
}

.info-content {
  display: flex;
  justify-content: space-between;
  align-items: center; /* Center items vertically */
  flex-wrap: wrap; /* Allow content to wrap on smaller screens */
}

.text-content {
  flex: 1;
  margin-right: 50px;
  min-width: 300px; /* Ensure minimum width for better responsiveness */
  text-align: left; /* Ensure text is left-aligned */
}

.text-content h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.text-content p {
  font-size: 1rem;
  margin-bottom: 30px;
}

.contact-us-button {
  background-color: #ff7e20;
  border: none;
  color: rgb(0, 0, 0);
  padding: 10px 20px;
  font-size: 12px;
  cursor: pointer;
  font-weight: bold;
  border-radius: 5px;
  text-transform: uppercase;
}

.contact-us-button:hover {
  transition: 0.2s;
  background-color: #000000;
  color: #ff7e20;
  transform: scale(1.05);
}

.image-content {
  flex: 1;
  text-align: left;
  position: relative;
  margin-left: 60px;
  margin-top: 50px;
}

.image-content img {
  width: 70%;
  max-width: 500px;
  position: relative;
  z-index: 2;
}

.image-overlay {
  position: absolute;
  top: -4%;
  left: -2%;
  width: 70%;
  height: 100%;
  background-color: #ff7e20;
  z-index: 1;
}

/* Media Queries for Small Screens */
@media (max-width: 768px) {
  .info-content {
    flex-direction: column;
    align-items: center; /* Center align items vertically */
    text-align: center; /* Center align text for the container */
  }

  .text-content {
    margin-right: 0;
    margin-bottom: 20px;
    text-align: left; /* Ensure text is left-aligned within the container */
  }

  .image-content {
    display: none; /* Hide image on small screens */
  }

  .contact-us-button {
    margin: 20px auto; /* Center the button with margin on small screens */
  }
}

@media (max-width: 480px) {
  .text-content h2 {
    font-size: 1.5rem; /* Smaller font size for smaller screens */
  }

  .text-content p {
    font-size: 0.9rem; /* Smaller font size for better readability */
  }

  .contact-us-button {
    padding: 8px 16px;
    font-size: 10px;
  }
}

/*Infosection*************************************************************************************/

/* Structural Section Styles */
.Structural-section {
  position: relative;
  width: 100%;
  height: 50%; /* Adjust height as needed */
  overflow: hidden;
}

.Structural-background {
  position: relative;
  background-image: url("structure.jpg"); /* Replace with your background image */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.Structural-background::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(116, 116, 116, 0.5); /* Black overlay with 50% opacity */
  z-index: 1; /* Ensures the overlay is above the background but below the content */
}

.Structural-content {
  position: relative;
  z-index: 2; /* Ensures content is above the overlay */
  text-align: center;
  padding: 20px;
}

.Structural-content h5 {
  font-size: 14px;
}

.Structural-content p {
  font-size: 2rem;
  max-width: 500px;
  line-height: 1.6;
}

/* Responsive Styles for Small Screens */
@media (max-width: 768px) {
  .Structural-background {
    height: auto; /* Let the height adjust to content on smaller screens */
    padding: 20px; /* Add padding for better spacing */
  }

  .Structural-content {
    padding: 10px; /* Reduce padding on smaller screens */
  }

  .Structural-content h5 {
    font-size: 12px; /* Smaller font size for heading */
  }

  .Structural-content p {
    font-size: 1.5rem; /* Smaller font size for paragraph */
    max-width: 100%; /* Allow paragraph to take full width */
  }
}

@media (max-width: 480px) {
  .Structural-background {
    padding: 10px; /* Further reduce padding for very small screens */
  }

  .Structural-content {
    padding: 5px; /* Further reduce padding */
  }

  .Structural-content h5 {
    font-size: 10px; /* Even smaller font size for heading */
  }

  .Structural-content p {
    font-size: 1.2rem; /* Further reduce font size for paragraph */
  }
}
/* Services Section Styles */
.services-section {
  background-color: white;
  width: 100vw; /* Full width of the viewport */
  padding: 0;
  margin: 0;
}

.services-grid {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr; /* Three columns: large-small-large */
  gap: 20px; /* Space between the items */
  align-items: center;
}

.service-item {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.service-item.large img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  display: block;
  transition: transform 0.3s ease;
}

.service-item.small img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  display: block;
  transition: transform 0.3s ease;
}

.service-item:hover img {
  transform: scale(1.1);
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4); /* Dark overlay */
  z-index: 1;
}

.service-text {
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: white;
  font-weight: bolder;
  font-size: 24px;
  border-radius: 5px;
  z-index: 2;
}

.small-container {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two columns for smaller items */
  grid-template-rows: repeat(2, 1fr); /* Two rows for smaller items */
}

/* Responsive Styles for Medium Screens */
/* Responsive Styles for Medium Screens (768px and below) */
@media (max-width: 768px) {
  .services-grid {
    grid-template-columns: 1fr; /* Single column layout for the entire grid */
  }

  .small-container {
    grid-template-columns: 1fr; /* Single column layout for small items */
    grid-template-rows: auto; /* Allow items to stack vertically */
  }

  .service-item {
    width: 100%; /* Ensure full width for all items */
  }

  .service-item.large img,
  .service-item.small img {
    width: 100%;
    height: 150px; /* Allow the image height to adjust while maintaining aspect ratio */
    object-fit: cover;
  }

  .service-item.small img {
    height: 150px; /* Make small images as large as the large images */
  }

  .service-item.large img {
    height: 150px; /* Consistent height for large images */
  }

  .service-text {
    font-size: 20px; /* Adjust font size for medium screens */
    bottom: 10px; /* Adjust positioning */
    left: 20px; /* Adjust positioning */
  }
}

/* Responsive Styles for Small Screens */
@media (max-width: 480px) {
  .services-grid {
    grid-template-columns: 1fr; /* Single column layout for the entire grid */
  }

  .small-container {
    grid-template-columns: 1fr; /* Single column layout for small items */
    grid-template-rows: auto; /* Allow items to stack vertically */
  }

  .service-item {
    width: 100%; /* Ensure full width for all items */
  }

  .service-item.large img,
  .service-item.small img {
    width: 100%;
    height: auto; /* Allow the image height to adjust while maintaining aspect ratio */
    object-fit: cover;
  }

  .service-item.small img {
    height: 300px; /* Make small images as large images */
  }

  .service-text {
    font-size: 16px; /* Further reduce font size */
    bottom: 10px; /* Adjust positioning */
    left: 10px; /* Adjust positioning */
  }
}


/* Reviews Section Styles */
.reviews-section {
  padding: 40px 10px; /* Increase padding for better spacing */
  background-color: #f4f4f4;
  text-align: center; /* Center-align text for a more cohesive look */
}

.reviews-section h2 {
  margin-bottom: 40px;
  color: #333; /* Use a more neutral, elegant color for the heading */
  font-size: 1.8rem;
  text-decoration: none; /* Remove underline for a cleaner look */
  font-weight: 600; /* Make heading bold for emphasis */
}

.reviews-cards {
  display: flex;
  justify-content: center; /* Center-align the cards */
  flex-wrap: wrap;
  gap: 20px; /* Increase space between cards */
}

.stars {
  margin: 10px 0;
}

.star {
  font-size: 20px;
}

.review-card {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 10px;
  text-align: center;
}

.review-card:hover {
  transform: scale(1.1);
  box-shadow: 0 6px 12px rgba(252, 161, 96, 0.5);
}


.review-text {
  margin-top: 15px; /* Add margin for spacing between image and text */
}

.review-text h3 {
  margin: 10px 0;
  color: #333;
  font-size: 1.1rem;
  font-weight: 600; /* Make names bold */
}

.review-text p {
  margin: 0;
  color: #555; /* Slightly darker gray for readability */
  font-size: 0.9rem;
  line-height: 1.5; /* Increase line height for readability */
}

@media (max-width: 768px) {
  .reviews-cards {
    flex-direction: column;
    align-items: center;
  }

  .review-card {
    max-width: 90%; /* Make sure the card width fits well on mobile */
  }
}

@media (max-width: 480px) {
  .reviews-section {
    padding: 40px 10px; /* Reduce padding on smaller screens */
  }

  .reviews-section h2 {
    font-size: 1.5rem;
  }

  .review-text h3 {
    font-size: 1rem; /* Adjust name size for smaller screens */
  }

  .review-text p { 
    font-size: 0.85rem;
  }
}

/* Add your styles here */



/* Projects Section Styles */
.projects-section {
  padding: 40px;
  background-color: #edf8fc;
  text-align: center;
}

.projects-section h2 {
  margin-bottom: 20px;
  color: #ff5e14; /* Primary color for heading */
  font-size: 2rem;
}

.projects-gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Default: three columns layout */
  gap: 20px; /* Space between images */
}

.project-item {
  position: relative;
  overflow: hidden;
  border-radius: 8px; /* Rounded corners */
}

.project-item img {
  width: 100%;
  height: 250px;
  display: block;
  transition: transform 0.5s ease, filter 0.5s ease;
}

.project-item:hover img {
  transform: scale(1.1); /* Zoom effect on hover */
  filter: brightness(70%); /* Darken the image on hover */
}

.project-item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4); /* Semi-transparent overlay */
  opacity: 0;
  transition: opacity 0.5s ease;
}

.project-item:hover::before {
  opacity: 1; /* Show overlay on hover */
}

.project-item:hover {
  cursor: pointer; /* Show pointer cursor on hover */
}

/* Responsive Styles for Small Screens */
@media (max-width: 768px) {
  .projects-gallery {
    grid-template-columns: repeat(
      2,
      1fr
    ); /* Two columns layout on small screens */
  }

  .projects-section h2 {
    font-size: 1.5rem; /* Adjust font size for heading */
  }
  .project-item img {
    height: 150px;
  }
}

@media (max-width: 480px) {
  .projects-gallery {
    grid-template-columns: 1fr; /* Single column layout on very small screens */
  }

  .projects-section h2 {
    font-size: 1.2rem; /* Further adjust font size for heading */
  }
  .project-item img {
    height: 150px;
  }
}

/* Stats Counter Section Styles */
.counter-section {
  padding: 10px 0;
  background-color: #f7f8fa;
  text-align: center;
}

.counter-items {
  display: flex;
  justify-content: center; /* Spaced out evenly */
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.counter-item {
  text-align: center;
  padding: 10px;
  border-right: solid 1px #999999;
  width: 200px;
}

.counter-number {
  font-size: 2rem;
  color: #ff6600;
  font-weight: bold;
}

.counter-item p {
  font-size: 1rem;
  color: #666;
  text-transform: uppercase;
  letter-spacing: 1px;
}
@media (max-width: 768px) {
  .counter-item {
    width: 130px;
  }

  .counter-number {
    font-size: 1.5rem;
  }

  .counter-item p {
    font-size: 0.75rem;
  }
}

@media (max-width: 480px) {
  .counter-item {
    width: 100px; /* Further reduce width for very small screens */
  }

  .counter-number {
    font-size: 1.25rem; /* Further reduce font size for numbers */
  }

  .counter-item p {
    font-size: 0.625rem; /* Further reduce font size for text */
  }
}

/*Consultation Section*/
.consultation-section {
  position: relative;
  width: 100%;
  height: 20rem;
  overflow: hidden;
}

.consultation-background {
  position: relative;
  background-image: url("engineer.jpg");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.consultation-background::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.consultation-content {
  position: relative;
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

.consultation-content h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.consultation-content p {
  font-size: 1rem;
  line-height: 1.6;
}

/* Responsive Styles for Small Screens */
@media (max-width: 768px) {
  .consultation-section {
    height: auto; /* Allow height to adjust based on content */
  }

  .consultation-background {
    background-attachment: scroll; /* Disable parallax effect on mobile */
  }

  .consultation-content {
    padding: 15px; /* Reduce padding for smaller screens */
  }

  .consultation-content h2 {
    font-size: 2rem; /* Reduce heading size */
  }

  .consultation-content p {
    font-size: 1rem; /* Reduce paragraph text size */
  }
}

@media (max-width: 480px) {
  .consultation-section {
    height: auto; /* Ensure height adapts to content */
  }

  .consultation-content {
    padding: 10px; /* Further reduce padding */
  }

  .consultation-content h2 {
    font-size: 1.75rem; /* Further reduce heading size */
  }

  .consultation-content p {
    font-size: 0.9rem; /* Further reduce paragraph text size */
  }
}

/*Consultation$$$$$$$$$$$$$$$$$*/

.footer {
  background-color: #0f0f0f;
  color: white;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  flex-wrap: wrap;
  margin-left: auto;  /* Center-align content */
  margin-right: auto; /* Center-align content */
  padding: 0 20px; /* Adds padding for better spacing */
  box-sizing: border-box; /* Include padding and border in element's total width */
}

.company-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 10px; /* Adds spacing around company info */
}

.logo {
  width: 190px;
  margin-bottom: 10px;
}

.social-icons a {
  color: #ff7e20;
  margin: 0 10px;
  font-size: 20px;
  text-decoration: none;
}

.company-info p {
  color: #aaa;
}

.footer-section {
  flex: 1;
  margin: 20px;
  min-width: 200px;
  text-align: left; /* Center-align content by default */
}

.footer-section h4 {
  margin-bottom: 20px;
  font-size: 18px;
}

.footer-section ul {
  list-style: none;
  padding: 0;
}

.footer-section ul li {
  margin-bottom: 10px;
}
.footer-section ul li:hover {
  color: #ff7e20; 
}

.footer-section ul li a {
  color: white;
  text-decoration: none;
  transition: color 0.3s;
}

.footer-section ul li a:hover {
  color: #ff7e20;
}

.footer-bottom {
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
  width: 100%;
  height: 100%;
  background-color: #0f0f0f;
  color: #aaa;
}

.footer-bottom p {
  margin: 5px 0;
  color: #aaa;
}
.footer-bottom p a {
  color: #ff7e20;
  text-decoration: none;

}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

/* Media Queries for Responsiveness */

@media (max-width: 768px) {
  .footer-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    margin-left: 0;
  }

  .company-info {
    text-align: center;
    margin: 0;
    width: 100%;
  }

  .footer-section {
    text-align: center; /* Changed to center for smaller screens */
    width: 100%;
    margin: 10px 0; /* Adds vertical spacing */
  }

  .footer-section h4 {
    font-size: 16px;
  }

  .footer-section ul li a {
    font-size: 14px;
  }

  .footer-bottom {
    padding: 10px 0;
    font-size: 12px;
  }

  .logo {
    width: 150px;
  }

  .social-icons a {
    margin: 0 5px;
    font-size: 18px;
  }
}

@media (max-width: 480px) {
  .footer-content {
    grid-template-columns: 1fr; /* Stacks content in one column */
    text-align: center; /* Ensures text is centered on the smallest screens */
  }

  .logo {
    width: 120px;
  }

  .footer-section ul li a {
    font-size: 12px;
  }

  .footer-section h4 {
    font-size: 14px;
  }

  .social-icons a {
    font-size: 16px;
  }

  .footer-bottom {
    font-size: 10px;
  }
}

/* Services Section Styles */
.services-section-structural {
  padding: 60px;
  background-color: white; /* Light background for the section */
}

.services-content-structural {
  max-width: 1200px; /* Set a max-width for the content container */
  margin: 0 auto; /* Center the container */
  display: flex;
  align-items: flex-start; /* Align items at the start of the container */
  justify-content: space-between;
  gap: 20px; /* Add space between image and text */
  flex-wrap: wrap; /* Allow wrapping for responsiveness */
}

.services-list-structural {
  flex: 1;
  padding-left: 20px;
}

.services-list-structural h2 {
  margin-bottom: 20px;
  color: #ff5e14; /* Primary color for heading */
  font-size: 2rem; /* Larger font size for better visibility */
}

.services-list-structural p {
  font-size: 16px;
  margin-bottom: 20px;
  line-height: 1.5; /* Improved line spacing for readability */
}

.services-items-structural {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns layout */
  gap: 20px; /* Space between service items */
}

.service-item-structural {
  display: flex;
  align-items: center;
  gap: 15px;
  background-color: white; /* Background color for items */
  padding: 15px;
  border-radius: 8px; /* Rounded corners for items */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Shadow for better separation */
}

.fa-icon-structural {
  font-size: 24px; /* Larger font size for icons */
  color: #ff5e14; /* Primary color for icons */
}

.service-item-structural h3 {
  margin: 0;
  font-size: 1rem; /* Adjust font size for headings */
  color: #333; /* Dark color for text */
}

.service-item-structural p {
  margin: 0;
  font-size: 0.875rem; /* Adjust font size for descriptions */
  color: #666; /* Lighter color for text */
}

.services-image-structural {
  flex: 1;
  text-align: center;
}

.services-image-structural img {
  width: 100%;
  max-width: 400px; /* Adjust as needed */
  height: 300px;
  margin-top: 120px; /* Adjust the margin as needed */
}

@media (max-width: 768px) {
  .services-content-structural {
    flex-direction: column;
    align-items: center;
  }

  .services-list-structural {
    padding-left: 0;
    text-align: center; /* Center text on smaller screens */
  }

  .services-items-structural {
    grid-template-columns: 1fr; /* Single column layout on small screens */
  }

  .services-image-structural {
    display: none;
  }
}

@media (max-width: 480px) {
  .services-list-structural h2 {
    font-size: 1.5rem; /* Adjust heading size for very small screens */
  }

  .services-list-structural p,
  .service-item-structural p {
    font-size: 0.85rem; /* Smaller font size for text */
  }
}

/* Our Services Section Styles */
.our-services {
  padding: 30px 30px;
  background-color: #f8f9fa; 
  color: #333; 
  text-align: center;
  
}

.services-header h2 {
  font-size: 2.5rem;
  color: #ff5e14;
  margin-bottom: 10px;
}


.services-header p {
  font-size: 1.2rem;
  font-weight: bolder;
  color: #555; 
  margin-bottom: 40px;
}

.services-intro p {
  font-size: 1.1rem;
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto 40px auto;
}

.services-principles {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

.principle {
  background-color: #fff; /* White background for principle boxes */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Light shadow for depth */
  max-width: 200px;
  width: 100%;
  padding: 20px;
  text-align: left;
  transition: transform 0.3s ease; /* Smooth hover effect */
}

.principle:hover {
  transform: translateY(-10px); /* Slight lift on hover */
}

.principle-icon {
  font-size: 1.5rem;
  font-weight: bold;
  color: #ff5e14; /* Primary color for icons/numbers */
  margin-bottom: 15px;
}

.principle-content h3 {
  font-size: 1.3rem;
  font-weight: bold;
  color: #555;
  margin-bottom: 10px;
}

.principle-content p {
  font-size: 1rem;
  color: #666;
  line-height: 1.5;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .services-principles {
    flex-direction: column;
    align-items: center;
  }
}

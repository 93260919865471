.App-header1 {
  width: 100%;
  height: auto;
}

.static-background1 {
  background-image: url("bg1.jpg");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 15rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-content1 {
  text-align: center;
  color: #ff5e14;
}

.header-content1 h1 {
  font-size: 2rem; /* Adjusted size for better visibility */
  margin: 0;
}

.header-content1 p {
  font-size: 1rem;
  margin: 20px auto 0;
  color: #0e2700;
  max-width: 80%; /* Adjusted for better spacing */
}

/* Base styles (unchanged for large screens) */
.App-header {
  position: relative;
  color: white;
}

.static-background {
  background-image: url("main.jpg");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: auto;
  height: 30rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 20px;
  position: relative;
  overflow: hidden;
  opacity: 80%;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.header-content {
  position: relative;
  z-index: 2;
  text-align: left;
  margin-left: 100px;
  color: white;
}

.header-content h1 {
  font-size: 3rem;
  margin-bottom: 20px;
}

.welcome-text {
  font-size: 2rem;
  color: #ffffff;
}

.highlight {
  font-size: 3rem;
  color: #ff7e20;
}

.text-and-button {
  display: flex;
  align-items: center;
  gap: 20px;
}

.header-content .text-and-button p {
  font-size: 1rem;
  margin: 0;
  color: white;
  width: 350px;
}

.learn-more-button {
  background-color: #ff7e20;
  border: none;
  color: rgb(0, 0, 0);
  padding: 10px 20px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
  text-transform: uppercase;
}

.learn-more-button:hover {
  transition: 0.2s;
  background-color: #000000;
  color: #ff7e20;
  transform: scale(1.05);
}

/* Parallax Effect */
.parallax {
  position: relative;
  overflow: hidden;
  height: 100vh;
  background: url('bg1.jpg') no-repeat center center fixed;
  background-size: cover;
}

.parallax-content {
  position: relative;
  z-index: 2;
  padding: 20px;
}

/* Responsive Styles for Small Screens */
@media (max-width: 768px) {
  .static-background {
    background-attachment: scroll; /* Remove parallax effect on mobile */
    height: 23rem; /* Let the height adjust to content */
  }

  .header-content {
    margin-left: 0; /* Remove left margin on small screens */
    text-align: center; /* Center-align text */
    padding: 0 15px; /* Add padding for better layout */
  }

  .header-content h1 {
    font-size: 2rem; /* Reduce heading size */
  }

  .welcome-text {
    font-size: 1.5rem; /* Smaller welcome text */
  }

  .highlight {
    font-size: 2.2rem; /* Adjust size of highlighted text */
  }

  .text-and-button {
    flex-direction: column; /* Stack text and button vertically */
    gap: 10px; /* Reduce gap between elements */
  }

  .header-content .text-and-button p {
    font-size: 0.9rem; /* Smaller text size */
    width: 100%; /* Let text take full width */
  }

  .learn-more-button {
    padding: 8px 16px; /* Adjust button size */
    font-size: 12px; /* Smaller font for button */
  }
}

@media (max-width: 480px) {
  .header-content h1 {
    font-size: 1.8rem; /* Even smaller for very small screens */
  }

  .welcome-text {
    font-size: 1.3rem;
  }

  .highlight {
    font-size: 1.8rem;
  }

  .text-and-button p {
    font-size: 0.8rem;
  }

  .learn-more-button {
    padding: 6px 12px;
    font-size: 10px;
  }
}

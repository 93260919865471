.gallery-section {
  padding: 50px;
  background-color: #f9f9f9;
  text-align: center;
  margin: 0 auto;
  max-width: 90%;
  background-color: rgb(245, 245, 245);
}

.gallery-title {
  font-size: 2rem;
  color: #333;
  margin-bottom: 30px;
  font-weight: bold;
}

.slide {
  position: relative;
  transition: transform 0.2s ease, filter 0.2s ease;
}

.slick-center .slide {
  transform: scale(1.1); /* Make the center slide larger */
  filter: brightness(1); /* Keep the center slide brighter */
  z-index: 2;
}

.slick-slide:not(.slick-center) .slide {
  transform: scale(0.7); /* Make the side slides smaller */
  filter: brightness(0.4); /* Darken the side slides */
  z-index: 1; /* Push side slides to the back */
}

.slide img {
  width: 350px;
  height: 250px;
  display: block;
  transition: border-color 0.3s ease; /* Smooth transition for border color */
  margin: 30px;
}

.arrow {
  font-size: 3rem;
  color: #333;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;
  cursor: pointer;
  border-radius: 50%;
  padding: 30px;
}

.arrow.prev {
  left: -50px;
}

.arrow.next {
  right: -50px; /* Adjust right arrow position */
}

.arrow:hover {
  color: #ff5e14;
}

/*Consultation Section*/
.pro-section {
  position: relative;
  width: 100%;
  height: 15rem;
  overflow: hidden;
}

.pro-background {
  position: relative;
  background-image: url("pro3.jpg");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pro-background::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.pro-content {
  position: relative;
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  z-index: 2;
}

.pro-content h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.pro-content p {
  font-size: 1rem;
  line-height: 1.6;
}

.contact-us-button2 {
  background-color: #ff7e20;
  border: none;
  color: rgb(0, 0, 0);
  padding: 10px 20px;
  font-size: 12px;
  cursor: pointer;
  font-weight: bold;
  border-radius: 5px;
  text-transform: uppercase;
}

.contact-us-button2:hover {
  transition: 0.2s;
  background-color: #000000;
  color: #ff7e20;
  transform: scale(1.05);
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
  background-color: transparent;
  height: 80px;
  position: relative;
}

.logo img {
  width: 70px;
  margin-left: 20px;
  margin-top: 10px;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 20px;
}

.nav-links li a {
  text-decoration: none;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #333;
}

.nav-links li a:hover {
  color: #ff7e20;
}

.hamburger {
  display: none;
  flex-direction: column;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 1001;
}

.bar {
  width: 25px;
  height: 3px;
  background-color: #333;
  margin: 3px 0;
}

.hamburger.hidden {
  display: none;
}

.close-btn {
  display: none;
  background: none;
  border: none;
  font-size: 24px;
  color: #333;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}

@media (max-width: 768px) {
  .nav-links {
    display: none;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.9);
    width: 40%;
    height: 100%;
    box-shadow: 0 2px 5px rgb(255, 126, 32);
    padding: 20px;
    gap: 10px;
    z-index: 1000;
  }

  .nav-links.active {
    display: flex;
    transform: translateX(0);
  }

  .hamburger {
    display: flex;
  }

  .close-btn {
    display: block;
    font-size: 1.7rem;
    color: #ff7e20;
    font-weight: bold;
  }

  .logo img {
    width: 75px;
    margin-top: 15px;
    justify-content: left;
  }

  .logo {
    margin-left: 0px;
  }
}

.Project-header {
    width: 100%;
    height: auto;
  }
  
  .project-static-background {
    background-image: url("timber.png");
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 15rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden; 
    -webkit-filter: grayscale(50%); 
    filter: grayscale(30%);    z-index: 1; 
  }
  
  .project-static-background::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
    
  }
  
  .project-content {
    text-align: center;
    color: #ff5e14;
    position: relative; /* Ensures content is above the overlay */
    z-index: 2; /* Above the overlay */
  }
  
  .project-content h1 {
    font-size: 2rem; /* Adjusted size for better visibility */
    margin: 0;
  }
  
  .project-content p {
    font-size: 1rem;
    margin: 20px auto 0;
    color: #ffffff;
  }
  